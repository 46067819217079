@import "../../sass/variables";

.login {
  padding: 0.25rem 2rem;
  border-radius: 1rem;
  outline: none;
  cursor: pointer;
  background-color: $mainColorPrimary;
  color: $basicColorPrimary;
  font-weight: bold;
  border: 1px solid $mainColorPrimary;
  &:hover {
    color: $mainColorPrimary;
    background-color: $basicColorPrimary;
    border: 1px solid $mainColorPrimary;
    &:visited {
      color: $mainColorPrimary;
    }
  }
  &:link {
    color: $mainColorPrimary;
    text-decoration: none;
  }
  &:active {
    color: $mainColorPrimary;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.popup-with-table-component {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.637);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-with-table-component .popup-with-table-container {
  background-color: #ffffff;
  width: 1024px;
  max-height: 35rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
}
.popup-with-table-component .popup-with-table-container .popup-with-table-subcontainer {
  margin: 1.5rem 3rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.popup-with-table-component .popup-with-table-container .pwt-title {
  font-size: 1.8rem;
  font-weight: 500;
  color: #014da1;
}
.popup-with-table-component .popup-with-table-container .pwtdt-txt {
  color: #014da1;
}
.popup-with-table-component .popup-with-table-container .pwt-table-container {
  max-height: 30rem;
  overflow-y: scroll;
}
.popup-with-table-component .popup-with-table-container .pwt-btns-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.popup-with-table-component .popup-with-table-container .pwt-btn-close {
  padding: 0.5rem 1.5rem;
  background-color: #ff0000;
  border: 1px solid #ff0000;
  color: #ffffff;
  font-weight: 600;
  border-radius: 0.5rem;
  cursor: pointer;
}
.popup-with-table-component .popup-with-table-container .pwt-btn-close:hover {
  background-color: #ffffff;
  color: #ff0000;
}/*# sourceMappingURL=PopupWithTableComponent.css.map */
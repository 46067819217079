@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.popup-bg {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.637);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-bg .popup-container {
  background-color: #ffffff;
  min-width: 15%;
  max-width: 40%;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 1rem;
  border-radius: 1rem;
}
.popup-bg .popup-container .info-arr-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.popup-bg .popup-container .popup-icon {
  width: 6rem;
}
.popup-bg .popup-container .popup-option-container {
  width: 100%;
  display: flex;
  -moz-column-gap: 3rem;
       column-gap: 3rem;
  justify-content: center;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .popup-container {
    max-width: 80% !important;
    font-size: 0.8rem;
  }
}/*# sourceMappingURL=PopUpComponent.css.map */
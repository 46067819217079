@import "../../sass/variables";

.doc-certification-table-container {
  border: 1px solid $mainColorPrimary;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .doc-certification-table-subcontainer {
    margin: 2rem 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    .dct-section-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .first-section {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 2rem;
    }
    .dct-sdl-title {
      font-size: 1.25rem;
      font-weight: 500;
      color: $mainColorPrimary;
    }
    .dct-sdl-info-container {
      overflow-y: scroll;
      height: 20rem;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .dct-sdl-info-container::-webkit-scrollbar {
      width: 6px;
    }

    .dct-sdl-info-container::-webkit-scrollbar-thumb {
      background-color: $scrollBarColorPrimary;
      border-radius: 4px;
    }
    .dct-section-doc-list-container {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .dct-deco-img {
      width: auto;
      height: 3rem;
    }
    .dct-section-btn {
      border: 1px solid $scrollBarColorPrimary;
      background-color: $scrollBarColorPrimary;
      color: $basicColorPrimary;
      padding: 0.5rem 2.5rem;
      border-radius: 0.5rem;
      cursor: pointer;
      font-size: 0.75rem;
      font-weight: 600;
      &:hover {
        border: 1px solid $mainColorPrimary;
        background-color: $mainColorPrimary;
      }
    }
    .dct-section-txt {
      color: $mainColorPrimary;
      font-size: 0.8rem;
    }
  }
}

//Resposive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .doc-certification-table-subcontainer {
    width: 100vw !important;
    overflow-x: scroll;
    .dct-section-container {
      width: 50rem !important;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .doc-certification-table-subcontainer {
    width: 100vw !important;
    overflow-x: scroll;
    .dct-section-container {
      width: 50rem !important;
    }
  }
}

@import "../../sass/variables";

.login-form-container {
  width: 100%;
  background-color: $basicColorPrimary;
  display: flex;
  justify-content: center;
  padding: 5% 0;
  border-radius: 1rem;
  .login-input-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    background-color: $basicColorPrimary;
    padding: 5% 0;
    border-radius: 1rem;
    row-gap: 1rem;
    .login-form-title {
      width: 100%;
      text-align: center;
      font-size: 0.8rem;
      color: $mainColorPrimary;
    }
    h1 {
      margin: 0;
    }
    .form-supercontainer {
      margin: 0 5%;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .login-fields-container {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .login-field-container {
      display: flex;
      flex-direction: column;
      row-gap: 0.2rem;
      position: relative;
    }
    .show-pass {
      position: absolute;
      right: 0.35rem;
      height: 1.9rem;
      top: 0.1rem;
      background-color: $basicColorPrimary;
      outline: none;
      border: 1px solid transparent;
      cursor: pointer;
    }
    .show-pass-icon {
      width: 1rem;
    }
    input {
      padding: 0.5rem 0 0.5rem 1rem;
      border-radius: 0.5rem;
      outline: none;
      border: 1px solid $mainColorPrimary;
    }
    .txt-error {
      margin: 0;
      font-size: 0.8rem;
      color: $alertBtnColorPrimary;
      display: none;
    }
    .show-error {
      display: unset;
    }
    .text-btn-container {
      font-size: 0.9rem;
      color: $mainColorPrimary;
    }
    .buttons-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    p {
      cursor: pointer;
      margin: 0;
    }
  }
}

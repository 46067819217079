@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.side-menu-container {
  height: 100vh;
  width: 14.5rem;
  overflow: hidden;
  z-index: 99;
}
.side-menu-container .side-menu-subcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  row-gap: 2rem;
  padding: 0 0 0 1.5rem;
  position: fixed;
  background-color: #014da1;
  left: 0;
  min-width: 13rem;
}
.side-menu-container .side-menu-subcontainer .line {
  border-top: 1px solid #ffffff;
  position: fixed;
  top: 4rem;
  width: 100%;
}
.side-menu-container .side-menu-subcontainer .logo-img-container {
  width: 9rem;
  margin-top: 1rem;
}
.side-menu-container .side-menu-subcontainer .logo-img {
  width: 100%;
}
.side-menu-container .side-menu-subcontainer .menus-container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  position: relative;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  direction: rtl;
  margin-bottom: 2rem;
}
.side-menu-container .side-menu-subcontainer .menus-container > * {
  direction: ltr;
}
.side-menu-container .side-menu-subcontainer .menus-container::-webkit-scrollbar {
  width: 0.5rem;
  height: 10px;
}
.side-menu-container .side-menu-subcontainer .menus-container::-webkit-scrollbar-thumb {
  background-color: #42ffff;
  border-radius: 5px;
}
.side-menu-container .side-menu-subcontainer .menus-container::-webkit-scrollbar-track {
  margin-right: 1rem;
  background-color: #ffffff;
  border-radius: 1rem;
}
.side-menu-container .side-menu-subcontainer .mc-hide-scroll {
  overflow-y: unset;
  overflow-x: unset;
}
.side-menu-container .side-menu-subcontainer .mc-show-scroll {
  overflow-x: hidden;
  overflow-y: auto;
}
.side-menu-container .side-menu-subcontainer .option-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  row-gap: 1rem;
}
.side-menu-container .side-menu-subcontainer .option-container:link {
  color: #ffffff;
  text-decoration: none;
}
.side-menu-container .side-menu-subcontainer .option-container:visited {
  color: #ffffff;
}
.side-menu-container .side-menu-subcontainer .option-container:active {
  color: #ffffff;
}
.side-menu-container .side-menu-subcontainer .current-select {
  border: 1px solid #ffffff;
  padding: 1rem;
  background-color: #ffffff;
  color: #014da1 !important;
  border-radius: 170px 0px 0px 169px;
  -webkit-border-radius: 170px 0px 0px 169px;
  -moz-border-radius: 170px 0px 0px 169px;
}
.side-menu-container .side-menu-subcontainer .option-icon {
  width: 2.5rem;
}
.side-menu-container .side-menu-subcontainer .title-option {
  width: 50%;
  text-align: center;
  font-weight: bold;
  font-size: 0.7rem;
}
.side-menu-container .side-menu-subcontainer .sm-footer-info-container {
  display: flex;
  align-items: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  margin-bottom: 3rem;
}
.side-menu-container .side-menu-subcontainer .sm-info-logo {
  font-size: 0.8rem;
  border-bottom: 2px solid #ffffff;
  border-top: 2px solid #ffffff;
  color: #ffffff;
  width: 4.5rem;
  text-align: center;
}
.side-menu-container .side-menu-subcontainer .sm-info-txt {
  font-size: 0.5rem;
  color: #ffffff;
  width: 7.5rem;
  text-align: center;
}
.side-menu-container .sms-remove-right-padding {
  padding: 0 0 0 1.25rem !important;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .side-menu-container {
    display: none;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .side-menu-container {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .side-menu-container {
    display: none;
  }
}/*# sourceMappingURL=SideMenusComponent.css.map */
@import "../../sass/variables";

.form-container-docs {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $basicColorPrimary;
  border: 1px solid $mainColorPrimary;
  border-radius: 1rem;
  position: relative;
  box-shadow: 10px 6px 18px -8px rgba(0, 0, 0, 0.67);
  padding: 2rem;
  row-gap: 1rem;
  margin-bottom: 1.5rem;
  .txt-input-container {
    display: grid;
    align-items: flex-end;
    grid-template-columns: 1fr;
    column-gap: 1rem;
    label {
      display: flex;
      flex-direction: column;
      color: $mainColorPrimary;
    }
    input {
      margin-top: 0.5rem;
      padding: 0.5rem 0;
      padding-left: 1rem;
      border-radius: 0.5rem !important;
      border: 2px solid $mainColorPrimary !important;
      width: 92%;
      font-family: "Roboto", sans-serif;
    }
  }
  .select-label {
    display: flex;
    flex-direction: column;
    color: $mainColorPrimary;
    font-style: italic;
  }
  .hide-label {
    display: none !important;
  }
  select {
    margin-top: 0.5rem;
    padding: 0.5rem 0;
    padding-left: 1rem;
    border-radius: 0.5rem !important;
    border: 2px solid $mainColorPrimary !important;
    width: 100%;
    font-family: "Roboto", sans-serif;
  }
  .inputs-file-container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    label {
      display: flex;
      flex-direction: column;
      color: $mainColorPrimary;
    }
    input[type="file"]::file-selector-button {
      margin: 0.5rem 0 0.25rem 0;
      border: 2px solid $scrollBarColorPrimary;
      padding: 0.2em 0.4em;
      border-radius: 0.2em;
      background-color: $scrollBarColorPrimary;
      transition: 1s;
      cursor: pointer;
      color: $mainColorPrimary;
      &:hover {
        background-color: $mainColorPrimary;
        border: 2px solid $mainColorPrimary;
        color: $basicColorPrimary;
      }
    }

    input[type="file"]::-ms-browse {
      background-color: $scrollBarColorPrimary;
      border: 2px solid $scrollBarColorPrimary;
      cursor: pointer;
      color: $mainColorPrimary;
      &:hover {
        background-color: $mainColorPrimary;
        border: 2px solid $mainColorPrimary;
        color: $basicColorPrimary;
      }
    }

    input[type="file"]::-webkit-file-upload-button {
      background-color: $scrollBarColorPrimary;
      border: 2px solid $scrollBarColorPrimary;
      cursor: pointer;
      color: $mainColorPrimary;
      &:hover {
        background-color: $mainColorPrimary;
        border: 2px solid $mainColorPrimary;
        color: $basicColorPrimary;
      }
    }
    .input-subtitle {
      font-size: 0.8rem;
    }
  }
  .form-doc-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    .form-doc-btn {
      width: 100%;
      height: 2.5rem;
      background-color: $btnColorSecondary;
      color: $mainColorPrimary;
      outline: none;
      border: 1px solid $btnColorSecondary;
      cursor: pointer;
      border-radius: 0.5rem;
      font-weight: bold;
      &:hover {
        background-color: $mainColorPrimary;
        color: $basicColorPrimary;
        border-color: $mainColorPrimary;
      }
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .form-container-docs {
    width: unset;
    .txt-input-container {
      grid-template-columns: unset;
      row-gap: 1rem;
    }
  }
}

@import "../../sass/variables";

.dct-sdl-info-elm-container {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  column-gap: 0.5rem;
  cursor: pointer;
  .dct-sdl-info-elm-checkbox {
    height: 1rem;
    width: 1rem;
  }
  .dct-sdl-info-elm-txt {
    color: $mainColorPrimary;
    font-size: 1rem;
  }
}

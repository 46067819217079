@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.dct-sdl-info-elm-container {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  cursor: pointer;
}
.dct-sdl-info-elm-container .dct-sdl-info-elm-checkbox {
  height: 1rem;
  width: 1rem;
}
.dct-sdl-info-elm-container .dct-sdl-info-elm-txt {
  color: #014da1;
  font-size: 1rem;
}/*# sourceMappingURL=DocCertificationElementComponent.css.map */
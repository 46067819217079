@import "../../sass/variables";

.validate-document-section {
  width: 100%;
  display: flex;
  padding-top: 4rem;
  .validate-document-subcontainer {
    width: 1024px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 5rem 1rem 0 27%;
    row-gap: 4rem;
    .form-with-steps-supercontainer {
      z-index: 1;
      width: 30rem;
    }
    .profile-deco {
      position: fixed;
      bottom: 0;
      right: 0;
      width: auto;
      height: 90vh;
      z-index: 0;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .validate-document-subcontainer {
    margin: 7rem 0 4rem 0 !important;
    .form-with-steps-supercontainer {
      width: 99% !important;
    }
    .profile-deco {
      display: none;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .validate-document-subcontainer {
    margin: 7rem 0 4rem 0 !important;
    align-items: center;
    .form-with-steps-supercontainer {
      width: 29rem !important;
    }
    .profile-deco {
      display: none;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .validate-document-subcontainer {
    margin: 12rem 0 4rem 0 !important;
    align-items: center;
    .form-with-steps-supercontainer {
      width: 29rem !important;
    }
    .profile-deco {
      display: none;
    }
  }
}

//Responsive Small Screen
@media screen and (min-width: $screenSm) and (max-width: calc($screenHd - 1px)) {
  .validate-document-subcontainer {
    margin-left: 15% !important;
    margin-bottom: 5rem !important;
  }
}

//Responsive HD Screen
@media screen and (min-width: $screenHd) {
  .validate-document-section {
    justify-content: center;
    .validate-document-subcontainer {
      margin-left: 0 !important;
      margin-bottom: 5rem !important;
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.doc-certification-table-container {
  border: 1px solid #014da1;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.doc-certification-table-container .doc-certification-table-subcontainer {
  margin: 2rem 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.doc-certification-table-container .doc-certification-table-subcontainer .dct-section-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.doc-certification-table-container .doc-certification-table-subcontainer .first-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.doc-certification-table-container .doc-certification-table-subcontainer .dct-sdl-title {
  font-size: 1.25rem;
  font-weight: 500;
  color: #014da1;
}
.doc-certification-table-container .doc-certification-table-subcontainer .dct-sdl-info-container {
  overflow-y: scroll;
  height: 20rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.doc-certification-table-container .doc-certification-table-subcontainer .dct-sdl-info-container::-webkit-scrollbar {
  width: 6px;
}
.doc-certification-table-container .doc-certification-table-subcontainer .dct-sdl-info-container::-webkit-scrollbar-thumb {
  background-color: #42ffff;
  border-radius: 4px;
}
.doc-certification-table-container .doc-certification-table-subcontainer .dct-section-doc-list-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.doc-certification-table-container .doc-certification-table-subcontainer .dct-deco-img {
  width: auto;
  height: 3rem;
}
.doc-certification-table-container .doc-certification-table-subcontainer .dct-section-btn {
  border: 1px solid #42ffff;
  background-color: #42ffff;
  color: #ffffff;
  padding: 0.5rem 2.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 600;
}
.doc-certification-table-container .doc-certification-table-subcontainer .dct-section-btn:hover {
  border: 1px solid #014da1;
  background-color: #014da1;
}
.doc-certification-table-container .doc-certification-table-subcontainer .dct-section-txt {
  color: #014da1;
  font-size: 0.8rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .doc-certification-table-subcontainer {
    width: 100vw !important;
    overflow-x: scroll;
  }
  .doc-certification-table-subcontainer .dct-section-container {
    width: 50rem !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .doc-certification-table-subcontainer {
    width: 100vw !important;
    overflow-x: scroll;
  }
  .doc-certification-table-subcontainer .dct-section-container {
    width: 50rem !important;
  }
}/*# sourceMappingURL=DocCertificationTableComponent.css.map */
@import "../../sass/variables";

.form-with-steps-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $basicColorPrimary;
  border: 1px solid $mainColorPrimary;
  border-radius: 0.5rem;
  position: relative;
  box-shadow: 10px 6px 18px -8px rgba(0, 0, 0, 0.67);
  row-gap: 1rem;
  margin-bottom: 1.5rem;
  .header-title-container {
    background-color: $mainColorPrimary;
    border: 1px solid $mainColorPrimary;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    color: $basicColorPrimary;
    padding: 1rem 3rem;
    text-align: center;
    font-size: 0.9rem;
  }
  .steps-graph-container {
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: bold;
    color: $mainColorPrimary;
    .step-bar-container {
      width: 10rem;
      height: 0.5rem;
      border-radius: 0.5rem;
      background-color: $barGraphListColorPrimary;
      .progress-bar {
        height: 0.5rem;
        background-color: $scrollBarColorPrimary;
        border-radius: 0.5rem;
      }
    }
  }
  .reset-btn-container {
    padding-left: 2rem;
    .reset-btn {
      padding: 0.25rem 0.5rem;
      background-color: $btnColorSecondary;
      color: $mainColorPrimary;
      outline: none;
      border: 1px solid $btnColorSecondary;
      cursor: pointer;
      border-radius: 0.5rem;
      font-weight: bold;
      &:hover {
        background-color: $mainColorPrimary;
        color: $basicColorPrimary;
        border-color: $mainColorPrimary;
      }
    }
  }
  .fws-form-container {
    padding: 0 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    .current-step {
      display: grid;
      align-items: flex-end;
      grid-template-columns: 1fr 1fr;
      column-gap: 1rem;
    }
    label {
      display: flex;
      flex-direction: column;
      color: $mainColorPrimary;
      font-style: italic;
    }
    input {
      margin-top: 0.5rem;
      padding: 0.5rem 0;
      padding-left: 1rem;
      border-radius: 0.5rem !important;
      border: 2px solid $mainColorPrimary !important;
      width: 90%;
      font-family: "Roboto", sans-serif;
    }
    select {
      margin-top: 0.5rem;
      padding: 0.5rem 0;
      padding-left: 1rem;
      border-radius: 0.5rem !important;
      border: 2px solid $mainColorPrimary !important;
      width: 100%;
      font-family: "Roboto", sans-serif;
    }
    .mandatory-text {
      font-size: 0.8rem;
      color: $errorMsgColor;
    }
    .hide {
      opacity: 0;
    }
    .fws-btns-container {
      display: flex;
      column-gap: 1rem;
    }
    .fws-btn {
      width: 100%;
      height: 2.5rem;
      background-color: $btnColorSecondary;
      color: $mainColorPrimary;
      outline: none;
      border: 1px solid $btnColorSecondary;
      cursor: pointer;
      border-radius: 0.5rem;
      font-weight: bold;
      &:hover {
        background-color: $mainColorPrimary;
        color: $basicColorPrimary;
        border-color: $mainColorPrimary;
      }
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .current-step {
    grid-template-columns: unset !important;
  }
  input {
    width: unset !important;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.login-form-container {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  padding: 5% 0;
  border-radius: 1rem;
}
.login-form-container .login-input-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 5% 0;
  border-radius: 1rem;
  row-gap: 1rem;
}
.login-form-container .login-input-container .login-form-title {
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
  color: #014da1;
}
.login-form-container .login-input-container h1 {
  margin: 0;
}
.login-form-container .login-input-container .form-supercontainer {
  margin: 0 5%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.login-form-container .login-input-container .login-fields-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.login-form-container .login-input-container .login-field-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;
  position: relative;
}
.login-form-container .login-input-container .show-pass {
  position: absolute;
  right: 0.35rem;
  height: 1.9rem;
  top: 0.1rem;
  background-color: #ffffff;
  outline: none;
  border: 1px solid transparent;
  cursor: pointer;
}
.login-form-container .login-input-container .show-pass-icon {
  width: 1rem;
}
.login-form-container .login-input-container input {
  padding: 0.5rem 0 0.5rem 1rem;
  border-radius: 0.5rem;
  outline: none;
  border: 1px solid #014da1;
}
.login-form-container .login-input-container .txt-error {
  margin: 0;
  font-size: 0.8rem;
  color: #ff0000;
  display: none;
}
.login-form-container .login-input-container .show-error {
  display: unset;
}
.login-form-container .login-input-container .text-btn-container {
  font-size: 0.9rem;
  color: #014da1;
}
.login-form-container .login-input-container .buttons-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.login-form-container .login-input-container p {
  cursor: pointer;
  margin: 0;
}/*# sourceMappingURL=LoginForm.css.map */
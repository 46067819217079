@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.popup-with-form-component {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.637);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-with-form-component .popup-with-form-container {
  background-color: #ffffff;
  max-width: 1024px;
  max-height: 35rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
}
.popup-with-form-component .popup-with-form-container .popup-with-form-subcontainer {
  margin: 1.5rem 3rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.popup-with-form-component .popup-with-form-container .pwf-title {
  font-size: 1.5rem;
  font-weight: 500;
  color: #014da1;
}
.popup-with-form-component .popup-with-form-container .pwf-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.popup-with-form-component .popup-with-form-container .pwf-input-form {
  border: 1px solid #014da1;
  border-radius: 0.5rem;
  padding: 0.5rem 0 0.5rem 1rem;
  outline: none;
  width: 100%;
}
.popup-with-form-component .popup-with-form-container .pwf-btns-container {
  width: 100%;
  display: flex;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  justify-content: flex-end;
}
.popup-with-form-component .popup-with-form-container .pwf-btn {
  padding: 0.5rem 1rem;
  background-color: #014da1;
  color: #ffffff;
  font-weight: bold;
  border: 1px solid #014da1;
  border-radius: 0.5rem;
  cursor: pointer;
}
.popup-with-form-component .popup-with-form-container .pwf-btn:hover {
  background-color: #ffffff;
  color: #014da1;
}
.popup-with-form-component .popup-with-form-container .cancel-btn {
  background-color: #ff0000;
  border: 1px solid #ff0000;
}
.popup-with-form-component .popup-with-form-container .cancel-btn:hover {
  color: #ff0000;
}/*# sourceMappingURL=PopupWithFormComponent.css.map */